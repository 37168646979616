import React, { FC, ReactElement } from 'react';

import './LegoTerms.scss'

const LegoTerms: FC = (): ReactElement => (
<>
<div className="legoTermsCont">
<h2>Motokiki Competition Terms and Conditions</h2>
  <ul>
    <li>The promoter is: Motokiki whose registered office is at 60 Portman Road, Reading United Kingdom, RG30 1EA.</li>
    <li>The competition is open to residents of the United Kingdom only, over 16 years of age.</li>
    <li>There is no entry fee and no purchase necessary to enter this competition.</li>
    <li>Winners will be chosen at random from all entries received.</li>
    <li>The social media platforms this competition will be running on are Instagram, Facebook and Twitter.</li>
    <li>To enter this promotion, entrants must follow <a href="https://www.instagram.com/motokikiuk/?hl=en">@motokikiuk on Instagram</a> or <a href="https://www.facebook.com/Motokikiuk/">@motokiki Facebook</a> or <a href="https://twitter.com/motokikiuk?lang=en">Twitter</a> and must like the post and tag a friend in the comments for an extra entry.</li>
    <li>The closing date for entry will be notified with each competition.</li>
    <li>The winner/s will be notified by email and/or DM on Twitter/Facebook/Instagram within 28 days of the closing date. If the winner/s cannot be contacted or do not claim the prize within 14 days of notification, we reserve the right to withdraw the prize from the winner/s and pick a replacement.</li>
    <li>The promoter will notify the winner when and where the prize is delivered.</li>
    <li>No responsibility can be accepted for entries not received for whatever reason.</li>
    <li>This competition is in no way sponsored, endorsed or administered by or associated with, Facebook, Twitter, Instagram or any other social network. You are providing your information to Motokiki and not to any other party. The information provided will be used in conjunction with the following Privacy Policy found at <a href="https://motokiki.com/privacy"> https://motokiki.com/privacy</a></li>
    <li>The promoter reserves the right to cancel or amend the competition and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God or any actual or anticipated breach of any applicable law or regulation or any other event outside of the promoter’s control. Any changes to the competition will be notified to entrants as soon as possible by the promoter.</li>
    <li>The promoter is not responsible for inaccurate prize details supplied to any entrant by any third party connected with this competition.</li>
    <li>The winner agrees to the use of his/her name and image in any publicity material, as well as their entry. Any personal data relating to the winner or any other entrants will be used solely in accordance with current UK data protection legislation and will not be disclosed to a third party without the entrant’s prior consent.</li>
    <li>Entry into the competition will be deemed as acceptance of these terms and conditions.</li>
  </ul>
</div>

</>
);
export default LegoTerms;

